import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Clock } from "../../../../common/icons/Clock";
import { isSameRewardPeriod } from "../../../../common/utils/campaign";
import {
  formatDateTime,
  isFutureStartDate,
} from "../../../../common/utils/date";
import { Button } from "../../../ui";
import {
  Campaign,
  CampaignReward,
  TargetItem,
  TargetItemDetail,
} from "../../campaign/campaign.type";
import { MileProgressBar } from "../component/MileProgressBar";
import {
  CampaignBulletList,
  CampaignBulletListItem,
  CampaignBulletTargetItemItem,
  CampaignBulletTargetItemList,
  CampaignContentDotLine,
  CampaignContentMultipleReward,
  CampaignContentMultipleRewardLine,
  CampaignContentNotes,
  CampaignContentNotesTitle,
  CampaignContentRewardText,
  CampaignContentTargetItem,
  CampaignContentTerm,
  CampaignContentText,
  CampaignContentWrapper,
  CampaignDetailBottomButton,
  CampaignMileWrapper,
  CampaignOverViewImage,
  CampaignOverViewTitle,
  CampaignOverViewWrapper,
  CampaignParticipateStatus,
  CampaignPurchasedItemWrapper,
  CampaignPurchasedNoItem,
  CampaignTargetItemDateTitle,
  CampaignTargetItemWrapper,
  ContentWrapper,
  MileCard,
  MileCardText,
  MileCardTextEndAt,
  MileCardTextWrapper,
  MileCardTitle,
  RewardEarnMethod,
  RewardImage,
  RewardMessage,
  RewardTitle,
  RewardWrapper,
  RewardWrapperMessage,
  SubTitleBar,
} from "../style/campaign_detail";
import {
  CampaignTargetItemButton,
  RewardDetailButton,
} from "../style/campaign_detail_participating";

export const CampaignDetailPreviewContainer: React.FC = () => {
  const { tenant_code, campaignId } = useParams();
  const [campaign, setCampaign] = useState<Campaign>({
    id: 1,
    acquisition_method: "",
    award_mile: 0,
    campaign_banner_url: "",
    campaign_categories: "",
    campaign_content: "",
    campaign_name: "",
    caution: "",
    publication_period_start: "",
    publication_period_end: "",
    implementation_period_start: "",
    implementation_period_end: "",
    minimum_purchase_amount: 0,
    is_promoted: false,
    promote_number: 0,
    meta_data: {
      entry_qualifications: {
        is_over_twenty_year_old: false,
      },
    },
    line_settings: {
      allow_adding_friends: false,
      expository_text: "",
      is_line_friend_required: false,
      filter_labels: [],
    },

    is_repeat: false,
    participating: false,

    rewards: [
      {
        id: 1,
        reward_name: "",
        banner_image_url: "",
        description: "",
        term_of_use: "",
        reward_type: "coupon",
        is_repetition: false,
        is_lottery: false,
        needed_mile: 100,
        jancode: "",
        aquired_at: "",
        reward_validity_period_start: "",
        reward_validity_period_end: "",
      },
    ],
    accumulated_mileage: 0,
    before_campaign_reward_id: 0,
    before_mileage: 0,
    next_campaign_reward_id: 0,
    next_mileage: 0,
    matchedRewards: [],
    purchase_logs: [],
    target_items: [
      {
        target_item_period_start: "",
        target_item_period_end: "",
        items: [],
      },
    ],
    line_friends: [],
  });

  const [targetItemCount, setTargetItemCount] = useState<number[]>([]);
  const countTargetItems = () => {
    return campaign?.target_items?.reduce(
      (acc: number, targetItems: TargetItem) => {
        return acc + targetItems.items.length;
      },
      0,
    );
  };

  useEffect(() => {
    // target itemの数をカウントする
    const countArray: number[] = [];
    let targetCount = 0;
    campaign?.target_items.map((targetItems: TargetItem, index: number) => {
      targetCount += targetItems.items.length;
      countArray.push(targetCount);
    }, 0);
    setTargetItemCount(countArray);
  }, [campaign]);

  useEffect(() => {
    const receiveMessage = (event: {
      origin: string;
      data: React.SetStateAction<Campaign>;
    }) => {
      if (event.origin + "/" !== process.env.REACT_APP_API_BASE) return;
      setCampaign(event.data);
    };

    window.addEventListener("message", receiveMessage);

    return () => {
      window.removeEventListener("message", receiveMessage);
    };
  }, []);

  return (
    <>
      <CampaignMileWrapper>
        <MileCard>
          <MileCardTextWrapper>
            <MileCardTitle>現在の獲得マイル</MileCardTitle>
            <MileCardText>0マイル</MileCardText>
          </MileCardTextWrapper>
          <MileProgressBar
            value={0}
            max={
              campaign?.rewards && campaign?.rewards.length !== 0
                ? campaign?.rewards[0]?.needed_mile
                : 0
            }
          ></MileProgressBar>
          <CampaignParticipateStatus>
            キャンペーンに参加していません
          </CampaignParticipateStatus>
          {/* 参加中の場合`あと***マイルで特典GET！` */}
        </MileCard>
        {/* TODO: ステータス */}
        <MileCardTextEndAt>
          <Clock style={{ marginRight: 5, verticalAlign: "middle" }} />
          {isFutureStartDate(campaign?.implementation_period_start)
            ? formatDateTime(campaign.implementation_period_start) + "から"
            : formatDateTime(campaign.implementation_period_end) + "まで"}
        </MileCardTextEndAt>
      </CampaignMileWrapper>
      <ContentWrapper>
        {/* Label */}
        <SubTitleBar>特典</SubTitleBar>
        {campaign?.rewards?.map((reward: CampaignReward, index: number) => {
          return (
            <>
              {reward.is_lottery && (
                <RewardTitle>
                  {reward.needed_mile}マイル獲得で抽選にチャレンジ！
                </RewardTitle>
              )}
              {!reward.is_lottery && (
                <RewardTitle>
                  {reward.needed_mile}マイル獲得でゲット！
                </RewardTitle>
              )}
              <RewardWrapper key={index}>
                <RewardImage src={reward.banner_image_url} />
                <RewardWrapperMessage>
                  <RewardMessage>{reward.reward_name}</RewardMessage>
                  <RewardDetailButton>詳細</RewardDetailButton>
                </RewardWrapperMessage>
              </RewardWrapper>
            </>
          );
        })}
        {/* Label */}
        <SubTitleBar>特典獲得方法</SubTitleBar>
        <RewardEarnMethod>{campaign?.acquisition_method}</RewardEarnMethod>
      </ContentWrapper>
      <CampaignContentWrapper>
        {/* Label */}
        <SubTitleBar>キャンペーン概要</SubTitleBar>
        <CampaignOverViewImage src={campaign?.campaign_banner_url} />
        <CampaignOverViewWrapper>
          <CampaignOverViewTitle>期間：</CampaignOverViewTitle>
          <CampaignContentTerm>
            実施期間
            <CampaignContentText>
              {formatDateTime(campaign?.implementation_period_start)} 〜<br />{" "}
              {formatDateTime(campaign?.implementation_period_end)}まで
            </CampaignContentText>
          </CampaignContentTerm>
          <CampaignContentDotLine></CampaignContentDotLine>
          <CampaignContentTerm>
            特典利用期間
            {isSameRewardPeriod(campaign?.rewards) &&
              campaign?.rewards &&
              campaign?.rewards.length !== 0 && (
                <>
                  <CampaignContentText>
                    {formatDateTime(
                      campaign?.rewards[0]?.reward_validity_period_start,
                    )}{" "}
                    〜<br />
                    {formatDateTime(
                      campaign?.rewards[0]?.reward_validity_period_end,
                    )}
                    まで
                  </CampaignContentText>
                </>
              )}
          </CampaignContentTerm>
          {!isSameRewardPeriod(campaign?.rewards) &&
            campaign?.rewards &&
            campaign?.rewards.length !== 0 &&
            campaign.rewards?.map((reward: CampaignReward, index: number) => {
              return (
                <>
                  <CampaignContentMultipleReward key={index}>
                    <CampaignContentRewardText>
                      {reward.reward_name}
                    </CampaignContentRewardText>
                    <CampaignContentRewardText>
                      {formatDateTime(reward?.reward_validity_period_start)} 〜
                      <br />
                      {formatDateTime(reward?.reward_validity_period_end)}
                      まで
                    </CampaignContentRewardText>
                  </CampaignContentMultipleReward>
                  <CampaignContentMultipleRewardLine></CampaignContentMultipleRewardLine>
                </>
              );
            })}
        </CampaignOverViewWrapper>
        {campaign?.campaign_content && (
          <CampaignOverViewWrapper>
            <CampaignOverViewTitle>キャンペーン内容：</CampaignOverViewTitle>
            <CampaignContentTerm>
              <CampaignContentText isFull>
                {campaign?.campaign_content}
              </CampaignContentText>
            </CampaignContentTerm>
          </CampaignOverViewWrapper>
        )}
        <CampaignOverViewWrapper>
          <CampaignOverViewTitle>最低購入金額：</CampaignOverViewTitle>
          <CampaignContentTerm>
            <CampaignContentText isFull>
              {"対象商品の1日の合計購入金額が" +
                campaign?.minimum_purchase_amount +
                "円（税込）以上"}
            </CampaignContentText>
          </CampaignContentTerm>
        </CampaignOverViewWrapper>
        <CampaignOverViewWrapper>
          <CampaignOverViewTitle>対象商品：</CampaignOverViewTitle>
          <CampaignContentTargetItem>
            <CampaignContentText>
              {campaign &&
                campaign?.target_items?.map(
                  (target_item: TargetItem, index: number) => {
                    if (targetItemCount[index] > 11) return;
                    return (
                      <CampaignTargetItemWrapper key={index}>
                        <CampaignTargetItemDateTitle>
                          {formatDateTime(target_item.target_item_period_start)}
                          ~{formatDateTime(target_item.target_item_period_end)}
                          まで
                        </CampaignTargetItemDateTitle>
                        <CampaignBulletTargetItemList>
                          {target_item?.items?.map(
                            (item: TargetItemDetail, idx: number) => {
                              return (
                                <CampaignBulletTargetItemItem key={idx}>
                                  ・
                                  {`${item.name}（マイル付与率：購入金額の${item.rate}%）`}
                                </CampaignBulletTargetItemItem>
                              );
                            },
                          )}
                        </CampaignBulletTargetItemList>
                      </CampaignTargetItemWrapper>
                    );
                  },
                )}
              {campaign?.target_items &&
                targetItemCount[targetItemCount.length - 1] > 11 && (
                  <CampaignTargetItemButton>
                    すべて見る
                  </CampaignTargetItemButton>
                )}
            </CampaignContentText>
          </CampaignContentTargetItem>
        </CampaignOverViewWrapper>
        <CampaignContentNotes>
          <CampaignContentNotesTitle>注意事項</CampaignContentNotesTitle>
          <CampaignBulletList>
            {campaign?.caution &&
              campaign?.caution
                ?.split("\n")
                .map((note: string, index: number) => {
                  return (
                    <CampaignBulletListItem key={index}>
                      {note}
                    </CampaignBulletListItem>
                  );
                })}
          </CampaignBulletList>
        </CampaignContentNotes>
      </CampaignContentWrapper>
      <CampaignPurchasedItemWrapper>
        {/* Label */}
        <SubTitleBar>商品購入履歴</SubTitleBar>
        <CampaignPurchasedNoItem>
          商品購入履歴は
          <br />
          ありません
        </CampaignPurchasedNoItem>
      </CampaignPurchasedItemWrapper>
      <CampaignDetailBottomButton>
        <Button
          text="キャンペーン一覧に戻る"
          styling="border"
          color="#000000"
        />
      </CampaignDetailBottomButton>
    </>
  );
};
