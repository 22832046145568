import { MenuItem, Select, styled } from "@mui/material";
import React, { useEffect } from "react";
import { useSelection } from "./hook";
import {
  DisabledSelection,
  ErrorMessage,
  RequiredLabel,
  SelectionLabel,
  SelectionSubtext,
} from "./style";
import { SelectionOption } from "./types";
type Props = {
  name?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  subtext?: string;
  error?: string | null;
  disabled?: boolean;
  noSelection?: boolean;
  value?: string;
  defaultValue?: string;
  onSelection?: (value: any) => void;
  options: SelectionOption[];
  classes?: React.CSSProperties;
  selectClasses?: React.CSSProperties;
};

const MuiSelect = styled(Select)(({ theme }) => ({
  textAlign: "left",
  "&.MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#999999",
    },
  },
}));
export const Selection: React.FC<Props> = ({
  name,
  label,
  placeholder,
  required,
  subtext,
  error,
  disabled,
  noSelection,
  value,
  defaultValue,
  onSelection,
  options,
  classes,
  selectClasses,
}) => {
  const {
    selection,
    showSelection,
    showError,
    handleOnClick,
    handleOnSelection,
    truncateString,
  } = useSelection(
    value
      ? options.find((option) => option.value == value)?.name ??
          placeholder ??
          ""
      : placeholder ?? "",
    onSelection,
  );

  useEffect(() => {
    // 年のデフォルト値
    if (name === "year" && value === undefined) {
      const selectedOption = options.find((option) => option.value == 1990);
      if (selectedOption) handleOnSelection(selectedOption);
    }
  }, [value, selection]);

  useEffect(() => {
    if (value) {
      const selectedOption = options.find((option) => option.value == value);
      if (!selectedOption) return;
      handleOnSelection(selectedOption);
    }
  }, [value]);

  return (
    <div style={{ margin: "16px 0", ...classes }}>
      {label && (
        <SelectionLabel>
          <div>{label}</div>
          {required && !disabled && <RequiredLabel>必須</RequiredLabel>}
        </SelectionLabel>
      )}
      <div onClick={disabled ? undefined : handleOnClick}>
        {disabled && (
          <DisabledSelection>
            {options.find((option) => option.value == value)?.name}
          </DisabledSelection>
        )}
        {!disabled && (
          <>
            <MuiSelect
              labelId={name}
              name={name}
              id={name}
              onChange={(e) => {
                handleOnSelection(
                  options.find((option) => option.value == e.target.value) ?? {
                    name: "",
                    value: "",
                  },
                );
              }}
              placeholder={placeholder}
              style={{
                width: "100%",
                height: "46px",
                borderRadius: "8px",
                color: selection !== placeholder ? "#000" : "#999",
                margin: "12px 0",
                ...selectClasses,
              }}
              defaultValue={defaultValue ? defaultValue : ""}
              displayEmpty
              disabled={noSelection}
            >
              <MenuItem value="">{placeholder}</MenuItem>
              {options.map((option, i) => (
                <MenuItem
                  onClick={() => {
                    handleOnSelection(option);
                  }}
                  key={`select-option-${name}-${i}`}
                  value={option.value}
                  style={{ width: "auto" }}
                >
                  {truncateString(option.name, 12)}{" "}
                  {option.count &&
                    option.count !== 0 &&
                    `（` + option.count + `件）`}
                </MenuItem>
              ))}
            </MuiSelect>
          </>
        )}
      </div>
      {error && showError && <ErrorMessage>{error}</ErrorMessage>}
      {subtext && <SelectionSubtext>{subtext}</SelectionSubtext>}
    </div>
  );
};
