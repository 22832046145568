import React, { useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { IconArrowRight } from "../../../../common/icons/IconArrowRight";
import useAssetStore, { fetchAssets } from "../../../../lib/asset/redux/store";
import { useLineLink } from "../../../../lib/hooks/useLineLink";
import { useNavigate } from "../../../../lib/hooks/useNavigate";
import { concatInformation } from "../../../../lib/information/redux/store";
import { templateStore } from "../../../template/store";
import { LoginType, settingStore } from "../../../template/store/setting";
import { Modal } from "../../../ui/modal";
import { useModal } from "../../../ui/modal/store";
import { ModalProps } from "../../../ui/modal/types";
import { useReaded } from "../../notification/hook/useReaded";
import { onLogout, onWithdraw } from "../action";
import { menus } from "../constant";
import { useMenu } from "../store";
import {
  CloseBtn,
  MenuBadge,
  MenuContainer,
  MenuItem,
  MenuItemInner,
  MenuSubtext,
  MenuText,
  MenuWrapper,
} from "../style";

type Props = {
  onClose: () => void;
};

const defaultModalProps: ModalProps = {
  isShow: false,
  title: "",
  subTitle: "",
  image_url: "",
  icon: <div />,
  LINE: false,
  contents: "",
  buttons: [],
  onClose: () => {
    /**/
  },
};

export const Menu: React.FC<Props> = ({ onClose }) => {
  const navigate = useNavigate();
  const { tenant_code } = useParams();
  const isShowWithdrawModal = useMenu((state) => state.isShowWithdrawModal);
  const {
    showLineLink,
    showAlreadyLineLinked,
    appNotInstalled,
    setAppNotInstalled,
  } = useLineLink("linkage");
  const [modalProps, setModalProps] = useState<ModalProps>(defaultModalProps);
  const isLoggedIn = templateStore((state) => state.isLoggedIn);
  const { hasUnread } = useReaded();
  const member = useAssetStore((state) => state.member);
  const isLineLinked = !!member?.line_service_id;
  const isLogin = localStorage.getItem("t__mm_access_token") !== null;
  const login_type: LoginType = settingStore((state) => state.login_type);
  const themeColor = settingStore((store) => store.theme_color);

  const onClickMenuItem = useCallback((menu?: any) => {
    const option: any = {};
    if (menu.action === "withdraw") onShowWithdrawModal();
    if (menu.route === "/terms_of_service") {
      option.state = {
        isMenu: true,
      };
    }
    if (menu.route === "/register") {
      return navigate("/terms_of_service");
    }
    if (menu.route) navigate(menu.route, option);
  }, []);

  const setIsShowWithdrawModal = useMenu(
    (state) => state.setIsShowWithdrawModal,
  );

  const onShowWithdrawModal = () => {
    setIsShowWithdrawModal(true);
  };

  const onShowLogoutModal = () => {
    setModalProps({
      isShow: true,
      title: "ログアウトしてよろしいですか？",
      subTitle: "",
      titleSize: "sm",
      contents: ``,
      buttons: [
        {
          name: "ログアウトする",
          callback: () => {
            onLogout();
            useModal.getState().showData({
              title: "ログアウトしました",
              contents: "",
              titleSize: "sm",
              buttons: [
                {
                  name: "閉じる",
                  callback: () => {
                    useModal.getState().closeModal();
                  },
                  color: themeColor,
                },
              ],
              isShow: true,
              isShowCoupon: false,
              isShowPoint: false,
            });
          },
        },
      ],
      onClose: () => {
        setModalProps(defaultModalProps);
      },
    });
  };
  useEffect(() => {
    if (appNotInstalled && isMobile) {
      setModalProps({
        isShow: true,
        title: "App Not Installed",
        subTitle: "",
        contents: ``,
        onClose: () => {
          setAppNotInstalled(false);
          setModalProps(defaultModalProps);
        },
      });
    }
  }, [appNotInstalled]);

  const menuData = menus(
    isLineLinked,
    login_type === "line" || login_type === "email_line",
    isLoggedIn,
    () => {
      if (isLineLinked) {
        showAlreadyLineLinked();
      } else {
        showLineLink();
      }
    },
    hasUnread(),
    onShowLogoutModal,
  );

  useEffect(() => {
    if (isLogin) {
      fetchAssets(tenant_code || "");
      concatInformation(tenant_code || "");
    }
  }, [isLogin]);

  return (
    <MenuWrapper>
      <CloseBtn onClick={onClose}>
        <img src="/assets/ui/modal/close.svg" alt="close" />
      </CloseBtn>
      <MenuContainer>
        {menuData.map((menu, i) => {
          return (
            menu.isEnable && (
              <MenuItem
                key={`menu-item-${i}`}
                onClick={
                  menu.onClick ? menu.onClick : () => onClickMenuItem(menu)
                }
              >
                <MenuItemInner>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <MenuText>{menu.name}</MenuText>
                      {menu.badge && (
                        <MenuBadge color={menu.badge.color}>
                          {menu.badge.text}
                        </MenuBadge>
                      )}
                    </div>
                    {menu.subtext && <MenuSubtext>{menu.subtext}</MenuSubtext>}
                  </div>
                  {menu.route && <IconArrowRight />}
                </MenuItemInner>
              </MenuItem>
            )
          );
        })}
      </MenuContainer>
      <Modal
        isShow={isShowWithdrawModal}
        title={`退会してよろしいですか？\n獲得したマイルや特典は\n無効になります。`}
        titleSize="sm"
        subTitle=""
        contents=""
        buttons={[
          {
            name: "退会する",
            callback: () => {
              if (tenant_code) {
                onWithdraw(tenant_code);
              }
            },
          },
        ]}
        onClose={() => setIsShowWithdrawModal(false)}
      />
      <Modal
        isShow={isShowWithdrawModal}
        title={`退会してよろしいですか？\n獲得したマイルや特典は\n無効になります。`}
        titleSize="sm"
        subTitle=""
        contents=""
        buttons={[
          {
            name: "退会する",
            callback: () => {
              if (tenant_code) {
                onWithdraw(tenant_code);
              }
            },
          },
        ]}
        onClose={() => setIsShowWithdrawModal(false)}
      />
      <Modal {...modalProps} />
    </MenuWrapper>
  );
};
