import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  fetchCampaignData,
  fetchEarndCampaignData,
} from "../../../../common/utils/campaign";
import { Button } from "../../button/Button";
import { useRoulette } from "../hook/useRoulette";
import {
  RouletteAnimContainer,
  RouletteAnimationBg,
  RouletteBg,
} from "../style/Roulette";
import {
  Bg,
  BtnContainer,
  CloseBtn,
  Container,
  SubTitle,
  Title,
} from "../style/style";
import { RouletteLose } from "./roulette/RouletteLose";
import { RouletteWin } from "./roulette/RouletteWin";

type ModalRouletteProps = {
  rewardId: number;
  isShow: boolean;
  image_url: string;
  content: any;
  point_auto_assignment: boolean;
  onClose: () => void;
};

export const ModalRoulette: React.FC<ModalRouletteProps> = (props) => {
  useEffect(() => {
    if (props.isShow || isLottery !== null) {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
      document.documentElement.style.overflow = "";
    }

    // クリーンアップ処理
    return () => {
      document.body.style.overflow = "";
      document.documentElement.style.overflow = "";
    };
  }, [props.isShow]);
  const [title, setTitle] = useState("獲得チャンス");
  const isRoulette = props.isShow;
  const rewardId = props.rewardId;
  const { isLottery, onLottery, onClose } = useRoulette("roulette_anim");
  const { tenant_code } = useParams();
  const [btnDisable, setBtnDisable] = useState(false);

  const close = useCallback(async () => {
    props.onClose();
    if (isLottery !== null) {
      onClose();
      await fetchCampaignData(tenant_code ?? "", true);
      fetchEarndCampaignData();
    }
  }, [props.onClose, isLottery]);

  if (!isRoulette) {
    return null;
  }

  return (
    <div>
      <Bg isOpen={isRoulette} />
      {isLottery === null && (
        <Container isOpen={isRoulette ? isRoulette : false} key={`roulette`}>
          <div>
            <SubTitle>{"特典抽選ルーレット"}</SubTitle>
            <Title>{title}</Title>
            <CloseBtn
              onClick={() => {
                close();
              }}
            >
              <img src="/assets/ui/modal/close.svg" alt="close" />
            </CloseBtn>
          </div>
          <RouletteAnimContainer>
            <RouletteBg>
              <img
                src="/assets/ui/modal/roulette.png"
                alt="ルーレット"
                width={"100%"}
                height={"100%"}
              />
            </RouletteBg>
            <RouletteAnimationBg className={`roulette_anim`} />
            <RouletteBg className={`result_anim`} />
          </RouletteAnimContainer>

          <BtnContainer>
            <Button
              key={"roulette start"}
              text={"ルーレットスタート"}
              onClick={() => {
                // 抽選実行
                onLottery(rewardId, props.point_auto_assignment).catch((e) => {
                  setBtnDisable(false);
                });
                setBtnDisable(true);
              }}
              disabled={btnDisable}
              containerStyle={{ marginBottom: "15px" }}
            />
            <Button
              text={"閉じる"}
              styling={"border"}
              color={"#000000"}
              onClick={() => close()}
            />
          </BtnContainer>
        </Container>
      )}
      {isLottery === true && (
        <RouletteWin
          close={close}
          image_url={props.image_url}
          content={props.content}
        />
      )}
      {isLottery === false && <RouletteLose close={close} />}
    </div>
  );
};
