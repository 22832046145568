import React, { useEffect } from "react";
import { formatDateTime } from "../../../common/utils/date";
import {
  TargetItem,
  TargetItemDetail,
} from "../../features/campaign/campaign.type";
import {
  BodyTextContainer,
  CampaignBulletTargetItemItem,
  CampaignBulletTargetItemList,
  CampaignContentText,
  CampaignTargetItemDateTitle,
  CampaignTargetItemWrapper,
} from "../../features/campaign_detail/style/campaign_detail";
import { Button } from "../button/Button";
import { Bg, ButtonWrapper, CloseBtn, Container, Title } from "./style/style";

type ModalProps = {
  isShow: boolean;
  title: string;
  target_items: TargetItem[];
  onClose(): void;
};

export const TargetItemModal: React.FC<ModalProps> = (props) => {
  useEffect(() => {
    if (props.isShow) {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
      document.documentElement.style.overflow = "";
    }

    // クリーンアップ処理
    return () => {
      document.body.style.overflow = "";
      document.documentElement.style.overflow = "";
    };
  }, [props.isShow]);
  return (
    <div>
      <Bg isOpen={props.isShow} />
      <Container isOpen={props.isShow}>
        <div>
          <Title>{props.title}</Title>
          <CloseBtn
            onClick={() => {
              props.onClose();
            }}
          >
            <img src="/assets/ui/modal/close.svg" alt="close" />
          </CloseBtn>
        </div>
        <BodyTextContainer>
          <CampaignContentText>
            {props.target_items &&
              props?.target_items.map(
                (target_item: TargetItem, index: number) => {
                  return (
                    <CampaignTargetItemWrapper key={index}>
                      <CampaignTargetItemDateTitle>
                        {formatDateTime(target_item.target_item_period_start)}~
                        {formatDateTime(target_item.target_item_period_end)}まで
                      </CampaignTargetItemDateTitle>
                      <CampaignBulletTargetItemList>
                        {target_item.items.map(
                          (item: TargetItemDetail, idx: number) => {
                            return (
                              <CampaignBulletTargetItemItem key={idx}>
                                ・
                                {`${item.name}（マイル付与率：購入金額の${item.rate}%）`}
                              </CampaignBulletTargetItemItem>
                            );
                          },
                        )}
                      </CampaignBulletTargetItemList>
                    </CampaignTargetItemWrapper>
                  );
                },
              )}
          </CampaignContentText>
        </BodyTextContainer>
        <ButtonWrapper>
          <Button
            text={"閉じる"}
            styling={"border"}
            color={"#000000"}
            onClick={() => props.onClose()}
          />
        </ButtonWrapper>
      </Container>
    </div>
  );
};
