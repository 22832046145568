import React, { useEffect, useState } from "react";
import {
  ScrollRestoration,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { AppConfig } from "../../../AppConfig";
import { isFutureDate } from "../../../common/utils/date";
import { LocalStorageManager } from "../../../core/storage/LocalStorageManager";
import useAssetStore from "../../../lib/asset/redux/store";
import { useNavigate } from "../../../lib/hooks/useNavigate";
import { displayRewards } from "../../../lib/modalReward/redux/selectors";
import { CampaignContainer } from "../../features/campaign";
import { RadioButtonGroup } from "../../features/campaign/component/ButtonGroup";
import { PickupInformation } from "../../features/campaign/component/PIckupInformation";
import { PAGE_TYPE } from "../../features/campaign/constant";
import { CampaignEarnedContainer } from "../../features/campaign/container/CampaignEarnedContainer";
import { CampaignParticipatingContainer } from "../../features/campaign/container/CampaignPaticipatingContainer";
import { clearEarnedCampaign } from "../../features/campaign/redux/earnedCampaign/store";
import { clearParticipatingCampaign } from "../../features/campaign/redux/participatingCampaign/store";
import { HeaderTemplate } from "../../template";
import { Base } from "../../template/style";
import { ModalReward } from "../../ui/modal/component/ModalReward";
import { ModalState } from "../../ui/modal/store";

export const Campaign: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const [selectedOption, setSelectedOption] = useState("progress");
  const navigate = useNavigate();
  const { tenant_code } = useParams();
  // 特典獲得モーダル
  const memberRewards = useAssetStore((state) => state.memberRewards);
  const user_id = useAssetStore((state) => state.member.id);
  const [rewards, setRewards] = useState<ModalState[]>([]);
  const isLogin = localStorage.getItem("t__mm_access_token") !== null;

  const handleSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    navigate(`?tab=${e.target.value}`);
  };
  useEffect(() => {
    setSelectedOption(tab || "progress");
  }, [tab]);

  useEffect(() => {
    if (!isLogin) {
      LocalStorageManager.Instance.removeObject(
        AppConfig.Instance.LocalStorageKey["participatingCampaigns"],
      );
      LocalStorageManager.Instance.removeObject(
        AppConfig.Instance.LocalStorageKey["earnedCampaigns"],
      );
      clearParticipatingCampaign();
      clearEarnedCampaign();
    }
  }, [isLogin, tab]);

  useEffect(() => {
    if (!tenant_code || !user_id) {
      return;
    }
    const displayedRewards: Array<string> =
      tenant_code && user_id ? displayRewards(tenant_code, user_id) : [];

    let _rewards: any[] = [];
    if (memberRewards.length !== 0) {
      _rewards = memberRewards
        .filter((reward: any) => {
          return !displayedRewards.find(
            (displayedReward: any) =>
              displayedReward.toString() === reward.id.toString(),
          );
        })
        .filter((reward: any) => isFutureDate(reward.used_at))
        .map((reward: any) => {
          return {
            reward_id: reward.id,
            isShow: true,
            isShowCoupon: false,
            isShowPoint: false,
            useAnimation: true,
            title: reward.name,
            subTitle: reward.is_lottery
              ? "抽選で特典が当たる！"
              : "必ず特典がもらえる",
            image_url: reward.banner_image_url,
            contents: "特典GET!",
          };
        });
    }
    setRewards(_rewards);
  }, [memberRewards, tenant_code, user_id]);

  let component;
  switch (tab) {
    case PAGE_TYPE.PROGRESS:
      component = <CampaignContainer />;
      break;
    case PAGE_TYPE.PARTICIPATING:
      component = <CampaignParticipatingContainer />;
      break;
    case PAGE_TYPE.EARNED:
      component = <CampaignEarnedContainer />;
      break;
    default:
      component = <CampaignContainer />;
      break;
  }

  return (
    <Base>
      <HeaderTemplate>
        <PickupInformation />
        <RadioButtonGroup
          selectedOption={selectedOption}
          onChange={handleSelectChange}
        />
        {component}
        <ModalReward rewardArray={rewards} />
      </HeaderTemplate>
      <ScrollRestoration />
    </Base>
  );
};
