import styled from "styled-components";

type Props = {
  isModal?: boolean;
};

export const HeaderContainer = styled.div`
  position: relative;
  width: 100%;
`;
export const Title = styled.h3`
  font-size: 20px;
  font-weight: bold;
  line-height: 1em;
  padding: 15px 0;
  margin: 15px 0;
`;
export const Message = styled.p`
  margin: 30px 50px 35px 50px;
  font-size: 16px;
  line-height: 1.5em;
`;
export const Contents = styled.div`
  position: relative;
  width: auto;
  margin: auto 10px;
`;

export const SelectionArea = styled.div`
  flex-direction: column; /* 子要素を縦に並べる */
  justify-content: center; /* 水平方向に中央揃え */
  align-items: center; /* 垂直方向に中央揃え */
  margin: 0 5px;
`;
export const SortResultArea = styled.div<Props>`
  text-align: left;
  margin: 0 10px;
  overflow-y: auto;
  border-top: 1px solid #dbdbdb;

  ${(props) =>
    props.isModal
      ? `
      height: 20svh;
      `
      : `
      height: 60svh;
      `}
`;
export const SubTitle = styled.div`
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  line-height: 1em;
  padding: 10px 0;
  margin: 10px 20px;
  justify-content: left;
`;
export const Breadcrumb = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 20px 0 20px 10px;
`;
