import { ModalLINEProps, ModalProps } from "../../ui/modal/types";

export const defaultModalProps: ModalProps = {
  isShow: false,
  title: "",
  subTitle: "",
  image_url: "",
  LINE: false,
  contents: "",
  buttons: [],
  onClose: () => {
    /**/
  },
};

export const defaultLINEModalProps: ModalLINEProps = {
  isShow: false,
  title: "",
  subTitle: "",
  image_url: "",
  LINE: false,
  contents: "",
  line_friends: [],
  line_settings: {
    allow_adding_friends: false,
    expository_text: "",
    is_line_friend_required: false,
    filter_labels: [],
  },
  buttons: [],
  onClose: () => {
    /**/
  },
};
