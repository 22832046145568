import { useCallback, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { AppConfig } from "../../AppConfig";
import {
  setMemberRegistrationEmail,
  setMemberRegistrationLineServiceId,
} from "../../components/features/member_registration/redux/store";
import { settingStore } from "../../components/template/store/setting";
import { useModal } from "../../components/ui/modal/store";
import { memberApi, oauthApi } from "../../core/http/openAPIClient";
import { LocalStorageManager } from "../../core/storage/LocalStorageManager";
import { useNavigate } from "./useNavigate";

export type LineLinkageMode = "login" | "register" | "linkage" | "none";

export const useLineLink = (mode: LineLinkageMode) => {
  const showData = useModal((state) => state.showData);
  const closeModal = useModal((state) => state.closeModal);
  const tenant_code = useParams().tenant_code;
  const navigate = useNavigate();
  const [appNotInstalled, setAppNotInstalled] = useState<boolean>(false);
  const themeColor = settingStore((store) => store.theme_color);
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("token");
  const navigateToPage = useCallback(() => {
    navigate(
      mode === "linkage"
        ? "/"
        : mode === "register"
          ? `/register/1?token=${token}`
          : mode === "login"
            ? "/campaign"
            : mode ?? "/",
    );
  }, []);

  const checkLineAppInstallation = (url: string) => {
    try {
      window.open(url, "_blank", "noopener,noreferrer");
      // Listen for the load event to see if the page loaded successfully
      setTimeout(() => {
        if (document.referrer.includes("line.me")) {
          // The Line app opened successfully, so it's likely installed
          return true;
        }
      }, 1000);
      // If the timeout completes without finding the referrer, assume the app isn't installed
      return false;
    } catch (e) {
      // An error occurred when trying to open the URL, likely because the app isn't installed
      return false;
    }
  };

  const lineLink = () => {
    const redirectUrl = token
      ? `${window.location.origin}/${tenant_code}/line_auth_callback?mode=${mode}&token=${token}`
      : `${window.location.origin}/${tenant_code}/line_auth_callback?mode=${mode}`;
    return oauthApi
      .oauthUrlApiV1TenantCodeOauthUrlPost(tenant_code ?? "", {
        tenant_code,
        oauth_service_code: "line",
        redirect_url: redirectUrl,
      })
      .then((res) => {
        // Check if we should redirect based on mode
        if (mode === "register" || mode === "linkage") {
          if (res.data.info_url) {
            const a = makeLink("__line_link", res.data.info_url, "_self");
            a?.click();
            document.body.removeChild(a);
          }
        } else if (mode === "login") {
          if (res.data.auth_url) {
            const a = makeLink("__line_login_link", res.data.auth_url, "_self");
            a?.click();
            document.body.removeChild(a);
          }
        }
        return res;
      })
      .catch((error) => {
        return error;
      });
  };

  const makeLink = (id: string, href: string, target: string) => {
    const aTag = document.createElement("a");
    aTag.href = href;
    aTag.target = target;
    aTag.id = id;
    document.body.appendChild(aTag);
    return document.getElementById(id) as HTMLAnchorElement;
  };

  const setLineServiceId = (line_service_id: string) => {
    return memberApi.oauthConnectApiV1TenantCodeMembersOauthConnectPost(
      tenant_code ?? "",
      {
        tenant_code,
        line_service_id,
      },
    );
  };

  const showLineLink = useCallback(() => {
    showData({
      title:
        mode === "linkage"
          ? "LINE IDと連携しますか？"
          : mode === "login"
            ? "LINE IDでログインしますか？"
            : mode === "register"
              ? "LINE IDで登録しますか？"
              : "",
      contents: "",
      buttons: [
        {
          name:
            mode === "linkage"
              ? "連携"
              : mode === "login"
                ? "ログイン"
                : mode === "register"
                  ? "登録"
                  : "",
          color: themeColor,
          callback: () => {
            lineLink();
            closeModal();
          },
        },
        {
          name: "閉じる",
          styling: "border",
          callback: () => {
            closeModal();
          },
        },
      ],
      isShow: true,
      isShowCoupon: false,
      isShowPoint: false,
    });
  }, []);

  const showCompleteLineLink = useCallback(() => {
    showData({
      title:
        mode === "linkage"
          ? "LINE IDと連携しました"
          : mode === "login"
            ? "LINE IDでログインしました"
            : mode === "register"
              ? "LINE IDで登録しました"
              : "",
      contents: "",
      buttons: [
        {
          name: "閉じる",
          styling: "border",
          callback: () => {
            closeModal();
          },
        },
      ],
      isShow: true,
      isShowCoupon: false,
      isShowPoint: false,
    });
  }, []);

  const showFailedLineLink = useCallback(() => {
    showData({
      title:
        mode === "linkage"
          ? "LINE IDと連携失敗しました"
          : mode === "login"
            ? "LINE IDでログイン失敗しました"
            : mode === "register"
              ? "LINE IDで登録失敗しました"
              : "",
      contents: "",
      buttons: [
        {
          name: "閉じる",
          styling: "border",
          callback: () => {
            closeModal();
          },
        },
      ],
      isShow: true,
      isShowCoupon: false,
      isShowPoint: false,
    });
  }, []);

  const showAlreadyLineLinked = useCallback(() => {
    showData({
      title: "LINE IDと連携済みです",
      contents: "",
      titleSize: "sm",
      buttons: [
        {
          name: "閉じる",
          styling: "border",
          callback: () => {
            closeModal();
          },
        },
      ],
      isShow: true,
      isShowCoupon: false,
      isShowPoint: false,
    });
  }, []);

  const lineLogin = (code: string, state: string) => {
    return oauthApi
      .oauthLoginApiV1TenantCodeOauthLoginPost(tenant_code ?? "", {
        tenant_code,
        code,
        state,
        redirect_url: `${window.location.origin}/${tenant_code}/line_auth_callback?mode=${mode}`,
      })
      .then((res) => {
        LocalStorageManager.Instance.saveObject(
          AppConfig.Instance.LocalStorageKey["accessToken"],
          res.data.access_token,
        );
        navigateToPage();
        return res;
      })
      .catch((error) => {
        showFailedLineLink();
        return error;
      });
  };

  const lineRegister = (code: string, state: string) => {
    return oauthApi
      .oauthInfoApiV1TenantCodeOauthInfoPost(tenant_code ?? "", {
        tenant_code,
        code,
        state,
        redirect_url: `${window.location.origin}/${tenant_code}/line_auth_callback?mode=${mode}&token=${token}`,
      })
      .then((res) => {
        if (res.data.auth_id) {
          setMemberRegistrationLineServiceId(res.data.auth_id);
        }
        if (res.data.email) {
          setMemberRegistrationEmail(res.data.email);
        }
        navigateToPage();
        return res;
      })
      .catch((error) => {
        navigateToPage();
        return error;
      });
  };

  const lineLinkage = (code: string, state: string) => {
    return oauthApi
      .oauthInfoApiV1TenantCodeOauthInfoPost(tenant_code ?? "", {
        tenant_code,
        code,
        state,
        redirect_url: `${window.location.origin}/${tenant_code}/line_auth_callback?mode=${mode}`,
      })
      .then((res) => {
        if (res.data.auth_id) {
          setLineServiceId(res.data.auth_id)
            .then(() => {
              showCompleteLineLink();
              navigateToPage();
            })
            .catch(() => {
              showFailedLineLink();
              navigateToPage();
            });
        }
        return res;
      })
      .catch((error) => {
        navigateToPage();
        return error;
      });
  };

  return {
    lineLink,
    setLineServiceId,
    lineLogin,
    lineRegister,
    lineLinkage,
    showLineLink,
    showCompleteLineLink,
    showAlreadyLineLinked,
    showFailedLineLink,
    appNotInstalled,
    setAppNotInstalled,
  };
};
